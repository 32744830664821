import { Component, Signal } from '@angular/core';
import { AuthService } from '../_services/auth.service';
import { User } from '../_models/user';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
  standalone: true,
})
export class ProfileComponent {
  currentUser: Signal<User | null>;

  constructor(private authService: AuthService) {
    this.currentUser = this.authService.currentUser;
  }
}
