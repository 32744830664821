@if (currentUser()) {
  <div>
    <header>
      <h3>
        <strong>{{ currentUser()?.username }}</strong> Profile
      </h3>
    </header>
    <p>
      <strong>Email:</strong>
      {{ currentUser()?.email }}
    </p>
    <strong>Roles:</strong>
    <ul>
      @for (role of currentUser()?.roles; track role) {
        <li>
          {{ role }}
        </li>
      }
    </ul>
  </div>
} @else {
  Please login.
}
