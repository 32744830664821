import { Component, Signal, WritableSignal, signal } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { Observable } from 'rxjs';
import { NgClass } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { User } from '../_models/user';
import { AuthService } from '../_services/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  standalone: true,
  imports: [ReactiveFormsModule, FormsModule, NgClass],
})
export class LoginComponent {
  form = {
    username: '',
    password: '',
  };

  errorMessage = '';

  roles: string[] = [];

  isLoggedIn: Signal<boolean>;

  currentUser: Signal<User | null>;

  isLoginFailed: WritableSignal<boolean> = signal(false);

  testing: Observable<boolean>;

  constructor(private authService: AuthService) {
    this.currentUser = this.authService.currentUser;
    this.isLoggedIn = this.authService.isLoggedIn;
    this.testing = toObservable(this.isLoginFailed);
  }

  onSubmit(): void {
    const { username, password } = this.form;

    this.authService.login(username, password).subscribe({
      next: (data) => {
        this.resetForm();
        console.log('loggedIn as', data.username);
      },
      error: (err) => {
        this.errorMessage = err.error.message;
        console.log('ERROR:', this.errorMessage);
        this.isLoginFailed.set(true);
      },
    });
  }

  private resetForm(): void {
    this.form = {
      username: '',
      password: '',
    };
  }
}
