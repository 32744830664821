import { Component, OnInit } from '@angular/core';
import { HelloService } from '../_services/hello.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  standalone: true,
})
export class HomeComponent implements OnInit {
  content?: string;

  constructor(private helloService: HelloService) {}

  ngOnInit(): void {
    this.helloService.getPublicHello().subscribe({
      next: (data) => {
        this.content = data;
      },
      error: (err) => {
        console.log(err);
        if (err.error) {
          this.content = JSON.parse(err.error).message;
        } else {
          this.content = `Error with status: ${err.status}`;
        }
      },
    });
  }
}
