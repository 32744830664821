import { Component, OnInit, Signal } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import {
  Router,
  RouterLink,
  RouterLinkActive,
  RouterOutlet,
} from '@angular/router';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { User } from './_models/user';
import { AuthService } from './_services/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [RouterLink, RouterLinkActive, RouterOutlet, TranslateModule],
})
export class AppComponent implements OnInit {
  isLoggedIn: Signal<boolean>;

  currentUser: Signal<User | null>;

  showAdminBoard = false;

  showModeratorBoard = false;

  title = 'AngularFrontend';

  constructor(
    private authService: AuthService,
    private router: Router,
    translate: TranslateService,
  ) {
    this.currentUser = this.authService.currentUser;
    this.isLoggedIn = this.authService.isLoggedIn;
    // instead of app.module.ts we can also set the default language here
    translate.setDefaultLang('en');
  }

  ngOnInit(): void {
    this.authService.getCurrentUser().subscribe({
      error: (error: HttpErrorResponse) => {
        if (error.status === 401) {
          this.router.navigate(['/']);
        } else {
          console.error('Something went wrong. Unable to fetch user details.');
        }
      },
    });
  }

  logout($event: Event): void {
    $event.preventDefault();
    this.authService.logout().subscribe({
      next: (res) => {
        console.log(res);
      },
      error: (err) => {
        console.log(err);
      },
    });
  }
}
