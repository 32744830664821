<div>
  <div>
    <img id="profile-img" src="assets/avatar_1x.png" alt="User's avatar" />
    @if (!isLoggedIn()) {
      <form
        class="form-container"
        name="form"
        (ngSubmit)="f.form.valid && onSubmit()"
        #f="ngForm"
        novalidate
      >
        <div class="form-field">
          <label for="username">Username</label>
          <input
            type="text"
            id="username"
            name="username"
            [(ngModel)]="form.username"
            required
            #username="ngModel"
            [ngClass]="{ 'is-invalid': f.submitted && username.errors }"
          />
          @if (username.errors && f.submitted) {
            <div class="error">Username is required!</div>
          }
        </div>
        <div class="form-field">
          <label for="password">Password</label>
          <input
            type="password"
            id="password"
            name="password"
            [(ngModel)]="form.password"
            required
            minlength="6"
            #password="ngModel"
            [ngClass]="{ 'is-invalid': f.submitted && password.errors }"
          />
          @if (password.errors && f.submitted) {
            <div>
              @if (password.errors["required"]) {
                <div class="error">Password is required</div>
              }
              @if (password.errors["minlength"]) {
                <div class="error">Password must be at least 6 characters</div>
              }
            </div>
          }
        </div>
        <div class="ml-auto">
          <button class="primary-button">Login</button>
        </div>
        <div>
          @if (f.submitted && isLoginFailed()) {
            <div role="alert" class="error-alert">
              Login failed: {{ errorMessage }}
            </div>
          }
        </div>
      </form>
    } @else {
      <div>Logged in as {{ currentUser()?.username }}.</div>
    }
  </div>
</div>
