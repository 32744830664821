import { Component, OnInit } from '@angular/core';
import { HelloService } from '../_services/hello.service';

@Component({
  selector: 'app-user',
  templateUrl: './user-content.component.html',
  styleUrls: ['./user-content.component.scss'],
  standalone: true,
})
export class UserContentComponent implements OnInit {
  content?: string;

  constructor(private helloService: HelloService) {}

  ngOnInit(): void {
    this.helloService.getPrivateHello().subscribe({
      next: (data) => {
        this.content = data;
      },
      error: (err) => {
        if (err.error) {
          try {
            const res = JSON.parse(err.error);
            this.content = res.message;
          } catch {
            this.content = `Error with status: ${err.status} - ${err.statusText}`;
          }
        } else {
          this.content = `Error with status: ${err.status}`;
        }
      },
    });
  }
}
