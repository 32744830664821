<div id="app" class="d-flex flex-column">
  <div class="p-1 project-title" data-test="project-title">
    <h1>
      <a href="#">{{ title | translate }}</a>
    </h1>
  </div>
  <nav>
    <ul>
      <li class="d-inline">
        <a href="/home" routerLink="home" routerLinkActive="active-link">{{
          "Home" | translate
        }}</a>
      </li>
      @if (showAdminBoard) {
        <li>
          <a href="/admin" routerLink="admin" routerLinkActive="active-link">{{
            "Admin Board" | translate
          }}</a>
        </li>
      }
      @if (showModeratorBoard) {
        <li>
          <a href="/mod" routerLink="mod" routerLinkActive="active-link">{{
            "Moderator Board" | translate
          }}</a>
        </li>
      }
      @if (isLoggedIn()) {
        <li>
          <a href="/user" routerLink="user" routerLinkActive="active-link">{{
            "User Content" | translate
          }}</a>
        </li>
        <li>
          <a
            href="/profile"
            routerLink="profile"
            routerLinkActive="active-link"
            >{{ currentUser()?.username }}</a
          >
        </li>
        <li>
          <a href (click)="logout($event)">{{ "LogOut" | translate }}</a>
        </li>
      } @else {
        <li class="d-inline">
          <a href="/login" routerLink="login" routerLinkActive="active-link">{{
            "Login" | translate
          }}</a>
        </li>
      }
    </ul>
  </nav>

  <div class="d-flex items-center justify-center p-1">
    <div class="w-50 p-1 card">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
