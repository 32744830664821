import {
  Injectable,
  Signal,
  WritableSignal,
  computed,
  signal,
} from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { User } from '../_models/user';

const AUTH_API = '/api/auth/';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/x-www-form-urlencoded',
  }),
};

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  currentUser: WritableSignal<User | null> = signal(null);

  isLoggedIn: Signal<boolean> = computed(() => !!this.currentUser());

  constructor(private http: HttpClient) {}

  login(username: string, password: string): Observable<User> {
    const body: HttpParams = new HttpParams()
      .set('username', username)
      .set('password', password);
    return this.http
      .post<User>(`${AUTH_API}login`, body.toString(), httpOptions)
      .pipe(tap((user: User) => this.setCurrentUser(user)));
  }

  logout(): Observable<void> {
    return this.http
      .post<void>(`${AUTH_API}logout`, null)
      .pipe(tap(() => this.setCurrentUser(null)));
  }

  getCurrentUser(): Observable<User> {
    return this.http
      .get<User>(`${AUTH_API}current-user`)
      .pipe(tap((user: User) => this.setCurrentUser(user)));
  }

  private setCurrentUser(user: User | null): void {
    this.currentUser.set(user);
  }
}
